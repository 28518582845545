
































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab2Procedure',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        procedureAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'In the space below, summarize the experimental procedure agreed upon by your group. This can be done in numbered format or paragraph form, but NOT point form.',
          fr: "Dans l'espace ci-dessous, résumez la procédure expérimentale convenue par votre groupe. Cela peut être fait sous forme numérotée ou sous forme de paragraphe, mais PAS sous forme de liste à puces.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
